import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'version-detail',
    templateUrl: './version-detail.component.html',
    styleUrls: ['./version-detail.component.scss'],
    animations: fuseAnimations
})
export class VersionDetailComponent {
    constructor(public _matDialogRef: MatDialogRef<VersionDetailComponent>) {}
}

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AssetsMonitoringService } from './services/asset-monitoring.service';
import { AssetsMonitoringToolComponent } from './assets-monitoring-tool.component';
import { AssetDetailComponent } from './asset-detail/asset-detail.component';
import { FuseWidgetModule } from '@fuse/components';
import { AssetConnectionService } from './services/asset-connection.service';
import {SharedMaterialModule} from '../shared/shared-material.module';

const routes = [
    {
        path     : 'tool-page',
        data: {toolName: 'assets-monitoring'},
        children: [
            {
                path: '',
                component: AssetsMonitoringToolComponent,
                pathMatch: 'full',
            },
            {
                path: ':id',
                component: AssetDetailComponent,
                pathMatch: 'full',
            },
        ]
    },
];
@NgModule({
    imports: [
        SharedMaterialModule,
        FuseWidgetModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        AssetsMonitoringToolComponent,
        AssetDetailComponent
    ],
    providers: [
        AssetsMonitoringService,
        AssetConnectionService
    ]
})
export class AssetsMonitoringToolModule {}

import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarComponent } from './navbar.component';
import { NavbarVerticalStyleModule } from './vertical/navbar-vertical-style/navbar-vertical-style.module';


@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        FuseSharedModule,
        NavbarVerticalStyleModule
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}

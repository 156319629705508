import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoIdToken
} from 'amazon-cognito-identity-js';


// Default pool data constant
const poolData = {
    UserPoolId: 'eu-central-1_hhjf5ggtW',
    ClientId: '7se0fcd5flcpr2n297p5k4q3ic'
};

// Initialization of user pool with provided data
const userPool = new CognitoUserPool(poolData);

@Injectable()
export class AuthenticationService
{
    cognitoUser: CognitoUser;
    cognitoUserSession: CognitoUserSession;
    userAttributes: any;

    // Authentication initialized
    authenticationIsLoading = new ReplaySubject<boolean>();

    // Error detection
    authenticationDidFail = new ReplaySubject<any>();

    // Sign in subject
    authenticationStatusChanged = new Subject<any>();

    token: CognitoIdToken;
    myCompanyId: string;
    myUserId: string;

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in user
     *
     * @param email
     * @param password
     */
    signInUser(email: string, password: string): void
    {
        // Notify that authentication started loading
        this.authenticationIsLoading.next(true);

        // Initialize authentication details
        const authData = {
            Username: email,
            Password: password
        };
        const authDetails = new AuthenticationDetails(authData);

        // Initialize cognito user
        const userData = {
            Username: email,
            Pool: userPool
        };
        this.cognitoUser = new CognitoUser(userData);

        // Bind
        const that = this;

        // Call user authentication from cognito library
        this.cognitoUser.authenticateUser(authDetails, {
            onSuccess(result: CognitoUserSession): void
            {
                // Assign retrieved user session
                that.cognitoUserSession = result;

                // Assign retrieved token
                that.token = result.getIdToken();

                // Try to load user attributes
                if (!that.retrieveAllUserAttributes()) {
                    // Authentication did fail from other reason, post error message
                    that.authenticationStatusChanged.next({ status: false });
                    that.authenticationDidFail.next({
                        status: true,
                        errorMessage: 'Invalid token!'
                    });
                } else {
                    that.authenticationStatusChanged.next({ status: true });
                    that.authenticationDidFail.next({ status: false});
                }

                // Notify about finished authentication
                that.authenticationIsLoading.next(false);
            },
            onFailure(err): void
            {
                // Check whether is user not verified to detect when to show confirmation pop-up
                if (err.code === 'UserNotConfirmedException')
                {
                    that.authenticationStatusChanged.next({ status: false });
                    that.authenticationDidFail.next({ status: false });
                }
                else
                {
                    // Authentication did fail from other reason, post error message
                    that.authenticationDidFail.next({
                        status: true,
                        errorMessage: err.message
                    });
                }
                // Notify about finished authentication
                that.authenticationIsLoading.next(false);
            }
        });
    }

    /**
     * Retrieve all user attributes
     *
     * @returns {Observable<boolean>}
     */
    private retrieveAllUserAttributes(): boolean {
        const decodedPayload = this.token.decodePayload();
        this.userAttributes = decodedPayload;
        if (!decodedPayload) {
            // Post error if decode failed
            return false;
        }

        if (decodedPayload['sub'])
        {
            this.myUserId = decodedPayload['sub'];
        }

        if (decodedPayload['custom:Company'])
        {
            this.myCompanyId = decodedPayload['custom:Company'];
        }

        return true;
    }

    /**
     * Get authenticated user
     *
     * @returns {CognitoUser}
     */
    private getAuthenticatedUser(): CognitoUser
    {
        // Return current user in user pool
        return userPool.getCurrentUser();
    }

    /**
     * Is authenticated
     *
     * @returns {Observable<boolean>}
     */
    isAuthenticated(): Observable<boolean>
    {
        // Check whether cognito user does exist - try to recover it from userpool
        this.cognitoUser = this.getAuthenticatedUser();

        // Prepare observable
        return Observable.create(observer => {
            if (!this.cognitoUser)
            {
                // If cognito user still does not exist post error
                observer.next(false);
                observer.complete();
            }
            else
            {
                // If cognito user exists, retrieve his session
                this.cognitoUser.getSession((err, session) => {
                    if (err)
                    {
                        // Post error if unable to retrieve user session
                        observer.next(false);
                        observer.complete();
                        return;
                    }

                    if (!session.isValid())
                    {
                        // Post error if user session is not valid
                        observer.next(false);
                        observer.complete();
                        return;
                    }

                    // Assign retrieved user session
                    this.cognitoUserSession = session;

                    // Assign session token
                    this.token = session.getIdToken();

                    if (this.myCompanyId)
                    {
                        // Post success if my company id exists
                        observer.next(true);
                        observer.complete();
                        return;
                    }

                    // Retrieve my and mine company id
                    observer.next(this.retrieveAllUserAttributes());
                    observer.complete();
                });
            }
        });
    }

    /**
     * Logout
     */
    logout(): void {
        if (!this.getAuthenticatedUser()) {
            return;
        }

        this.getAuthenticatedUser().signOut();

        // Reset all values
        this.myCompanyId = null;
        this.myUserId = null;
        this.cognitoUser = null;

        this.authenticationStatusChanged.next({ status: false, logout: true });
        this.authenticationIsLoading.next(false);
    }

    /**
     * Refresh token and session if session is not valid
     */
    refreshToken(): Observable<boolean> {
        return Observable.create(observer => {
            if (!this.cognitoUserSession) {
                console.log('AuthenticationService - error: no user session persisted');
                observer.next(false);
                observer.complete();
            }

            const refresh_token = this.cognitoUserSession.getRefreshToken();
            this.cognitoUser.refreshSession(refresh_token, (err, session: CognitoUserSession) => {
                if (!err && session) {
                    this.cognitoUserSession = session;
                    this.token = session.getIdToken();

                    observer.next(this.retrieveAllUserAttributes());
                    observer.complete();
                } else {
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }

}

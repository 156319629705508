import { ActionReducerMap } from '@ngrx/store';

// tslint:disable-next-line: no-empty-interface
export interface AppState {

}

export const reducers: ActionReducerMap<AppState> = {
    
}

import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { 
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatTreeModule,
    MatDividerModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDatepickerModule,
    MatStepperModule,
    MatRadioModule,
    MatListModule,
    MatSidenavModule,
    MatGridListModule,
    MatPaginatorModule,
    MatTabsModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule
} from '@angular/material';

@NgModule({
    imports: [
        FuseSharedModule,

        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatTreeModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatChipsModule

    ],
    exports: [
        FuseSharedModule,

        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatTreeModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatChipsModule
    ]
})

export class SharedMaterialModule
{
}

import { ImpressumDetailComponent } from './impressum-detail/impressum-detail.component';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { VersionDetailComponent } from './version-detail/version-detail.component';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{

    dialogRef: any;

    /**
     * Constructor
     */
    constructor(public _matDialog: MatDialog)
    {
    }

    showVersion(): void {
        this.dialogRef = this._matDialog.open(VersionDetailComponent, {
            panelClass: 'dialogNarrow'
        });
    }

    openDigicorWeb(): void {
        window.open('https://www.digicor-project.eu/');
    }

    showImpressum(): void {
        this.dialogRef = this._matDialog.open(ImpressumDetailComponent, {
            panelClass: 'dialogNarrow'
        });
    }
}

import { AssetConnectionService } from './../services/asset-connection.service';
import { fuseAnimations } from '@fuse/animations';
import { Component, OnDestroy } from '@angular/core';
import { Asset } from '../models/asset.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AssetsMonitoringService } from '../services/asset-monitoring.service';
import { DataSource } from '@angular/cdk/table';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material";
import {AxisPosition} from "../models/axisPosition.model";
import {MotorConsumption} from "../models/motorConsumption.model";

@Component({
    selector: 'asset-detail',
    templateUrl: './asset-detail.component.html',
    styleUrls: ['./asset-detail.component.scss'],
    animations: fuseAnimations
})
export class AssetDetailComponent implements OnDestroy {
    asset: Asset;
    assetIndex: number;

    axisDataSource: MatTableDataSource<AxisPosition>;
    axisDisplayedColumns = ['name', 'value'];

    powerConsumptionDataSource: MatTableDataSource<MotorConsumption>;
    powerConsumptionDisplayedColumns = ['name', 'minimum',  'average', 'maximum'];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _router
     * @param _route
     * @param _assetsMonitoringService
     * @param _assetConnectionService
     */
    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _assetsMonitoringService: AssetsMonitoringService,
        private _assetConnectionService: AssetConnectionService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this._route.params.subscribe((params: Params) => {
            if (params['id']) {
                this.assetIndex = params['id'];
                if (this._assetsMonitoringService.availableAssets && this.assetIndex < this._assetsMonitoringService.availableAssets.length) {
                    this.asset = this._assetsMonitoringService.availableAssets[this.assetIndex];

                    // Load axis datasource
                    this.axisDataSource = new MatTableDataSource([]);

                    // Load power consumptions
                    this.powerConsumptionDataSource = new MatTableDataSource([]);

                    this._assetConnectionService.connectAsset(this.asset);

                }
            } else {
                this.close();
                return;
            }

            if (!this.asset) {
                this.close();
            }
        });

        this._assetConnectionService.assetStatusChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
            (asset: Asset) => {
                this.asset = asset;

                if (this.asset.positionData && this.asset.positionData.axisPositions) {
                    // Load axis datasource
                    this.axisDataSource = new MatTableDataSource(this.asset.positionData.axisPositions);
                }

                if (this.asset.consumptionData && this.asset.consumptionData.motorConsumptions) {
                    // Load power consumptions
                    this.powerConsumptionDataSource = new MatTableDataSource(this.asset.consumptionData.motorConsumptions);
                }
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._assetConnectionService.disconnectAsset();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Close
     */
    close(): void
    {
        this._router.navigate(['../'], {relativeTo: this._route});
    }
}

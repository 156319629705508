import { NgModule } from '@angular/core';
import { VerticalLayoutModule } from './vertical/vertical-layout/vertical-layout.module';

@NgModule({
    imports: [
        VerticalLayoutModule
    ],
    exports: [
        VerticalLayoutModule,
    ]
})
export class LayoutModule
{
}

import { NgModule } from '@angular/core';

// Services
import { SnackbarService } from './services/snackbar.service';

// Modules
import { SharedMaterialModule } from './shared-material.module';

@NgModule({
    imports     : [
        SharedMaterialModule,
    ],
    providers: [
        SnackbarService,
    ]
})

export class SharedModule
{
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

///////////////////////////////////////
/** @TG TEMP Enums - will be recovered from backend in future version **/
///////////////////////////////////////
export const acceptCurrencyNegotiationEnum: string[] = ['Yes', 'No'];

const certificationsEnumValues: string[] = [
    'A2LA', 'AFRA', 'AMO', 'ANAC', 'ANACArgentina', 'ANACBrazil', 'AQAP', 'ARSA', 'ARSS', 'AS9100', 'ASA-100', 'ASTRIUMApproval', 'ATRApproval', 'AirbusApproval',
    'AvioItalyApproval', 'Avio_GE_Approval', 'BDCA', 'BoeingApproval', 'BombardierApproval', 'CAA', 'CAAB', 'CAAC', 'CAACI', 'CAAI', 'CAANZ', 'CAAP', 'CAAPNG',
    'CAAS', 'CAAV', 'CAD', 'CASA', 'CASE', 'CCR', 'COC', 'Capabilities', 'CathayApproval', 'CertificateOfAccreditation', 'CompleteListofAMICerts', 'DAC', 'DCAT',
    'DD2345', 'DGAC', 'DGCA', 'DIGAMC', 'DNA', 'DNV', 'DOT', 'DasellApproval', 'DassaultAviationApproval', 'DiehlApproval', 'DrugTestingCert', 'EADSApproval',
    'EASA', 'EASA21J296', 'EASAPART145', 'EASAPART147', 'EASAPART21G', 'EASAPART21J', 'EFWApproval', 'EN9100', 'ENAC', 'EmbraerApproval', 'EurocopterApproval',
    'FAA', 'FAAOpsSpecs', 'FAR145', 'FinmeccanicaApproval', 'GACA', 'GoodrichApproval', 'HoneywellApproval', 'IAF', 'IQNET', 'ISO', 'ISO14001', 'ISO17025',
    'ISO9000', 'ISO9001', 'ISO9001_AS9100', 'ISO9002', 'ISO9100', 'ISO9110', 'JAA', 'JAR145', 'JCAB', 'JetAviationApproval', 'KANNAD', 'LBA', 'LineCard',
    'LockheedApproval', 'LufthansaTechnikApproval', 'NADCAP', 'NATOAWACSaircraftparts', 'NIST', 'OHBApproval', 'OrganizationChart', 'Others', 'PCAA', 'PECAL',
    'PRI', 'PWC901A_CApproval', 'PiaggioAeroApproval', 'PilatusApproval', 'PremiumAerotecApproval', 'QCAA', 'QualityControlManual', 'RollsRoyce', 'TCCA', 'TCO',
    'Thailand', 'UCCA', 'UKAS'
];
const contractTypesEnumValues: string[] = [
    'Build_to_print', 'Buy', 'Contract_for_work_and_labour', 'Design_and_build', 'Service_contract'
];
const targetRegionsEnumValues: string[] = [
    'Africa', 'Asia', 'Australia', 'Europe', 'Germany', 'Middle_East', 'North_America', 'South_America'
];
const minAnnualTurnoverRequiredEnumValues: string[] = [
    '0', '1M', '10M', '100M', '500M', '1B', '10B', '100B', '500B'
];
const annualTurnoverEnumValues: string[] = [
    '0-1000000', '1000000-10000000', '10000000-100000000', '100000000-500000000', '1000000000-10000000000', '10000000000-100000000000', '100000000000-500000000000',
    '500000000-1000000000', 'Unknown'
];
const minNumberOfEmployeesRequiredEnumValues: string[] = [
    '1', '2', '10', '50', '200', '500', '1000', '5000', '10000', '100000', '500000'
];
const numberOfEmployeesEnumValues: string[] = [
    '1', '100001-500000', '10001-100000', '1001-5000', '11-50', '2-10', '201-500', '5001-10000', '501-1000', '51-200', 'Unknown', 'moreThan500000'
];
const technologiesEnumValues = [
    {
        'Simulation': ['simulation_Organisation', 'simulation_Information_Flow', 'simulation_General', 'simulation_Test', 'simulation_3D-Simulation',
            'simulation_Material_Flow', 'simulation_Stress', 'simulation_Digital_Mock-Up']
    }, {
        'Heat_Treatment': ['heat_treatment_Annealing', 'heat_treatment_Solution_Treatment',
            'heat_treatment_Artificial_Ageing', 'heat_treatment_Tempering', 'heat_treatment_General']
    }, {
        'Surface_Treatment': ['surface_treatment_Powder_Coating',
            'surface_treatment_Pickling', 'surface_treatment_Painting', 'surface_treatment_Stripping', 'surface_treatment_Polishing', 'surface_treatment_Coating',
            'surface_treatment_Inmould_Coating', 'surface_treatment_Electroplating', 'surface_treatment_General', 'surface_treatment_Anodizing']
    },
    {'Measurement': ['measurement_General', 'measurement_Measurement_Div.']}, {'Shot_Peening': ['shot_peening_General', 'shot_peening_Shot_Peening_Div.']},
    {
        'Thermoplastic': ['thermoplastic_Pressing', 'thermoplastic_Deep_Forming', 'thermoplastic_Pultrusion',
            'thermoplastic_Extrusion', 'thermoplastic_Coating', 'thermoplastic_Moulding', 'thermoplastic_General']
    },
    {'Machining': ['machining_General', 'machining_Sawing', 'machining_Milling', 'machining_Turning']},
    {'Rubber': ['rubber_Lay-Up', 'rubber_General', 'rubber_Vulcanizing', 'rubber_Coating', 'rubber_Pressing', 'rubber_Extrusion', 'rubber_Wrapping', 'rubber_Moulding']},
    {'Welding': ['welding_Plasma_Welding', 'welding_General', 'welding_Resistance_Welding', 'welding_Inert-Gas_Shielded_Arc_Welding', 'welding_Laser-Beam_Welding']},
    {'Forming': ['forming_General', 'forming_Rapid_Prototyping', 'forming_Sintering', 'forming_Casting']}, {
        'Duroplastic': ['duroplastic_Moulding', 'duroplastic_Pressing',
            'duroplastic_Winding', 'duroplastic_Lay-Up', 'duroplastic_General']
    }, {
        'Chip_Removing_Processes': ['chip_removing_processes_Milling', 'chip_removing_processes_General',
            'chip_removing_processes_Lapping', 'chip_removing_processes_Honing', 'chip_removing_processes_Broaching', 'chip_removing_processes_Drilling']
    },
    {'Cutting': ['cutting_Cutting_Div.', 'cutting_Slitting', 'cutting_General', 'cutting_Jet-Cutting', 'cutting_Laser-Cutting']},
    {'Chemical_Milling': ['chemical_milling_General', 'chemical_milling_Chemical_Milling_Div.']}, {
        'Testing_Methods': ['testing_methods_General', 'testing_methods_Others',
            'testing_methods_X-Ray', 'testing_methods_Ultrasonic', 'testing_methods_Dye-Penetrant']
    }, {
        'Grinding': ['grinding_Abrasive_Brushing', 'grinding_Belt_Grinding',
            'grinding_General']
    }, {
        'Shaping': ['shaping_Swaging', 'shaping_Widening', 'shaping_Drawing', 'shaping_Bending',
            'shaping_Elongation', 'shaping_Primary_Shaping', 'shaping_General', 'shaping_Super_Plastic_Forming', 'shaping_Pressure_Forming', 'shaping_Rolling',
            'shaping_Free-Forming', 'shaping_Shear_Forming']
    }
];
const materialsEnumValues = [
    {'Auxiliary_Products': ['auxiliary_products_Lubricants', 'auxiliary_products_General']},
    {'Wood': ['wood_Solid_Wood', 'wood_General', 'wood_Veneer']},
    {'Coatings': ['coatings_General', 'coatings_Inmold_Coating', 'coatings_Varnish']},
    {'Composite': ['composite_General', 'composite_Honeycomb_Panels', 'composite_Grp_Carbon_Fiber', 'composite_Crp_Carbon_Fiber']},
    {'Textile_Drapery': ['textile_drapery_Leather', 'textile_drapery_General', 'textile_drapery_Cloth']},
    {'Insulation': ['insulation_General', 'insulation_Glasswool', 'insulation_Foam']},
    {'Metal': ['metal_Titanium', 'metal_General', 'metal_Steel_And_Iron', 'metal_Aluminium_Alloy', 'metal_Stainless_Steel']},
    {'Rubber': ['rubber_Synthetic', 'rubber_Thermoplasts', 'rubber_Foam', 'rubber_Silicone', 'rubber_General', 'rubber_Polymers']},
    {'Plastic': ['plastic_General', 'plastic_Thermoplast', 'plastic_Duroplast']}, {'Chemicals': ['chemicals_General']}, {'Gases': ['gases_General']}, {'Fluids': ['fluids_General']}
];
const ataChaptersEnumValues: string[] = [
    'ATA_05', 'ATA_06', 'ATA_07', 'ATA_08', 'ATA_09', 'ATA_10', 'ATA_11', 'ATA_12', 'ATA_18', 'ATA_20', 'ATA_21', 'ATA_22', 'ATA_23', 'ATA_24', 'ATA_25', 'ATA_26',
    'ATA_27', 'ATA_28', 'ATA_29', 'ATA_30', 'ATA_31', 'ATA_32', 'ATA_33', 'ATA_34', 'ATA_35', 'ATA_36', 'ATA_37', 'ATA_38', 'ATA_39', 'ATA_41', 'ATA_45', 'ATA_46',
    'ATA_49', 'ATA_51', 'ATA_52', 'ATA_53', 'ATA_54', 'ATA_55', 'ATA_56', 'ATA_57', 'ATA_60', 'ATA_61', 'ATA_62', 'ATA_63', 'ATA_64', 'ATA_65', 'ATA_66', 'ATA_67',
    'ATA_70', 'ATA_71', 'ATA_72', 'ATA_73', 'ATA_74', 'ATA_75', 'ATA_76', 'ATA_77', 'ATA_78', 'ATA_79', 'ATA_80', 'ATA_81', 'ATA_82', 'ATA_83', 'ATA_84', 'ATA_91'
];
const departmentEnumValues: string[] = [
    'Engineering', 'Manufacturing', 'Sales', 'Service'
];
const unitsEnumValues: string[] = [
    'Litres', 'Pieces', 'Meter', 'Kg', 'Cubic_Meter', 'something else'
];
const goalsEnumValues: string[] = [
    'Plan&Manage', 'Design&Develop', 'IntegrateDesign', 'Source', 'Make', 'Assemble', 'Deliver'
];
const productSpecificationEnumValues: string[] = [
    'Electronic', 'Mechanical', 'Software'
];
const locationsEnumValues: string[] = [];

const url_api_gateway = 'https://api.digicor-platform.net/tdms';

@Injectable()
export class SharedEnums {
    annualTurnoverEnum: string[];
    minAnnualTurnoverRequiredEnum: string[];
    numberOfEmployeesEnum: string[];
    minNumberOfEmployeesRequiredEnum: string[];
    certificationsEnum: string[];
    contractTypesEnum: string[];
    targetRegionsEnum: string[];
    ataChaptersEnum: string[];
    technologiesEnum: string[];
    materialsEnum: string[];
    departmentTypes: string[];
    productSpecificationEnum: string[];
    goalsEnum: string[];
    unitsEnum: string[];
    locationsEnum: string[];

    // Error detection
    sharedEnumsUpdated = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    getSharedEnums(token): Observable<any> {
        return this.http
            .get<any>(url_api_gateway + '/ontology/enums', {
                observe: 'body',
                responseType: 'json',
                headers: {Authorization: 'Bearer ' + token}
            })
            .map(res => {
                let ontologyEnums = {};
                if (res && res[0]) {
                    ontologyEnums = res[0];
                }

                this.certificationsEnum = ontologyEnums['Certifications'] || certificationsEnumValues;
                this.contractTypesEnum = ontologyEnums['ContractType'] || contractTypesEnumValues;
                this.targetRegionsEnum = ontologyEnums['TargetRegion'] || targetRegionsEnumValues;
                this.ataChaptersEnum = ontologyEnums['ATA'] ? ontologyEnums['ATA'][0] || ataChaptersEnumValues : ataChaptersEnumValues;
                this.annualTurnoverEnum = ontologyEnums['AnnualTurnover'] || annualTurnoverEnumValues;
                this.minAnnualTurnoverRequiredEnum = ontologyEnums['minAnnualTurnoverRequired'] || minAnnualTurnoverRequiredEnumValues;
                this.numberOfEmployeesEnum = ontologyEnums['NoOfEmployees'] || numberOfEmployeesEnumValues;
                this.minNumberOfEmployeesRequiredEnum = ontologyEnums['minNumberOfEmployeesRequired'] || minNumberOfEmployeesRequiredEnumValues;
                this.materialsEnum = ontologyEnums['Materials'] || materialsEnumValues;
                this.technologiesEnum = ontologyEnums['Technology'] || technologiesEnumValues;
                this.departmentTypes = ontologyEnums['Departments'] || departmentEnumValues;
                this.productSpecificationEnum = ontologyEnums['ProductSpecification'] || productSpecificationEnumValues;
                this.goalsEnum = ontologyEnums['Goals'] || goalsEnumValues;
                this.unitsEnum = ontologyEnums['UnitsEnum'] || unitsEnumValues;
                this.locationsEnum = ontologyEnums['Locations'] || locationsEnumValues;

                this.sharedEnumsUpdated.next(true);

                return res;
            });
    }
}

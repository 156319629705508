import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

@Injectable()
export class SnackbarService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public snackBar: MatSnackBar) {}

    /**
     * Show snackbar with message on the top
     * 
     * @param message 
     * @param timeout 
     */
    openSnackBar(message: string, timeout = 1000): void {
        this.snackBar.open(message, 'Hide', {
            duration: timeout,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }
}

import { takeUntil } from 'rxjs/internal/operators';
import { fuseAnimations } from '@fuse/animations';
import { AssetsMonitoringService } from './services/asset-monitoring.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subject } from 'rxjs';
import { Asset } from './models/asset.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'assets-monitoring-tool',
    templateUrl: './assets-monitoring-tool.component.html',
    styleUrls: ['./assets-monitoring-tool.component.scss'],
    animations: fuseAnimations
})
export class AssetsMonitoringToolComponent implements OnInit, OnDestroy {
    displayedColumns = ['id', 'name'];
    dataSource: AssetsDataSource | null;
    allAssets: Asset[];

    private _unsubscribeAll: Subject<any>;

    constructor(
        private assetsMonitoringService: AssetsMonitoringService,
        private _router: Router,
        private _route: ActivatedRoute
    
    )
    {
        // Init private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new AssetsDataSource(this.assetsMonitoringService);

        this.assetsMonitoringService.assetsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(assets => {
                this.allAssets = assets;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    
    /**
     * Open asset
     * 
     * @param index 
     */
    openAsset(index: number): void
    {
        this._router.navigate([index], {relativeTo: this._route});
    }
}  

// ProductionPlan datasource helper class
export class AssetsDataSource extends DataSource<any> {
    /**
     * Constructor
     *
     * @param assetsMonitoringService
     */
    constructor(private assetsMonitoringService: AssetsMonitoringService) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        return this.assetsMonitoringService.assetsChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}

import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'impressum-detail',
    templateUrl: './impressum-detail.component.html',
    styleUrls: ['./impressum-detail.component.scss'],
    animations: fuseAnimations
})
export class ImpressumDetailComponent {
    constructor (public _matDialogRef: MatDialogRef<ImpressumDetailComponent>) {}
}

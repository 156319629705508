import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    errorMessage = '';
    loginDidFail = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param _fuseConfigService
     * @param _formBuilder
     * @param _fuseSplashScreenService
     * @param _authenticationService
     * @param _router
     * @param _route
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _authenticationService: AuthenticationService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {
        this._fuseSplashScreenService.show();

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Subscribe to authentication did fail event
        this._authenticationService.authenticationDidFail
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((loginDidFail: any) => {
                    if (!loginDidFail.status) {
                        // Store information about login failure - to display received error message
                        this.loginDidFail = loginDidFail.status;
                        this.errorMessage = loginDidFail.errorMessage;

                        this._fuseSplashScreenService.hide();
                    }
                }
            );

        // Subscribe to authentication status changed event
        this._authenticationService.authenticationStatusChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((loggedIn: any) => {
                    // Check whether the login was successful
                    if (loggedIn.status)
                    {
                        // Navigate
                        this._router.navigate(['/tool-page']);
                    }
                }
            );

        // Subscribe to route params and check whether it contains credentials
        this._route.queryParams.subscribe(
            (params: Params) => {
                if (params['email'] && params['pwd']) {
                    // Log in!
                    this._authenticationService.signInUser(params['email'], params['pwd']);
                } else {
                    // Stay on login
                    this._fuseSplashScreenService.hide();
                }
            }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On login event
     */
    onLogin(): void {
        // Try to sign in user with provided credentials
        this._authenticationService.signInUser(this.loginForm.value['email'], this.loginForm.value['password']);
    }
}

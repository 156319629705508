import {Asset} from '../models/asset.model';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../authentication/services/authentication.service';

@Injectable()
export class AssetConnectionService implements OnDestroy {
    private asset: Asset;

    assetStatusChanged: BehaviorSubject<Asset>;

    timeout: any;

    /**
     * Constructor
     *
     * @param httpClient
     * @param _authenticationService
     */
    constructor(
        private httpClient: HttpClient,
        private _authenticationService: AuthenticationService) {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.disconnectAsset();
    }

    /**
     * Connect asset
     *
     * @param asset
     */
    connectAsset(asset: Asset): void {
        this.asset = asset;

        // Initialize connection
        this.assetStatusChanged = new BehaviorSubject(this.asset);

        this.updateAssetPosition(this);
    }


    /**
     * Update asset position
     */
    updateAssetPosition(that: any): void {
        that.getAssetStatus(this.asset.assetId.id).subscribe(
            (assetStatus: Asset) => {
                that.asset = assetStatus;
                that.assetStatusChanged.next(that.asset);

                that.timeout = setTimeout(() => {
                    that.updateAssetPosition(that);
                }, 2000);
            }
        );
    }

    /**
     * Disconnect asset
     */
    disconnectAsset(): void {
        this.asset = null;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (this.assetStatusChanged) {
            this.assetStatusChanged.complete();
            this.assetStatusChanged = null;
        }
    }

    /**
     * Get asset status
     *
     * @param assetId
     */
    getAssetStatus(assetId: string): Observable<any> {
        return this.httpClient.get('https://assets-monitoring.digicor-platform.eu/assets-monitoring/' + assetId,
            {
                observe: 'body',
                responseType: 'json',
                headers: {
                    Authorization:
                        'Bearer ' +
                        this._authenticationService.token.getJwtToken()
                }
            }).map(status => {
            // do we want to do something?
            return status;
        });
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Asset } from '../models/asset.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import 'rxjs/add/operator/map';

@Injectable()
export class AssetsMonitoringService implements OnDestroy {
    availableAssets: Asset[] = [];
    assetsChanged: BehaviorSubject<Asset[]>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param httpClient
     * @param _authenticationService
     */
    constructor(
        private httpClient: HttpClient,
        private _authenticationService: AuthenticationService
    )
    {
        this.assetsChanged = new BehaviorSubject(this.availableAssets);

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.assetsChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((availableAssets: Asset[]) => {
                if (availableAssets) {
                    this.availableAssets = [...availableAssets];
                }
            });

        this.getAvailableAssets().subscribe(
            response => {}
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get available assets
     */
    getAvailableAssets(): Observable<Asset[]> {
        return this.httpClient.get('https://assets-monitoring.digicor-platform.eu/assets-monitoring',
            {
                observe: 'body',
                responseType: 'json',
                headers: {
                    Authorization:
                        'Bearer ' +
                        this._authenticationService.token.getJwtToken()
                }
            }).map((assets: Asset[]) => {
            // do we want to do something?
            if (assets) {
                this.assetsChanged.next(assets);
            }
            return assets;
        });
    }
}

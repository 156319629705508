import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Store
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/app.reducer';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

// Modules
import { LayoutModule } from 'app/layout/layout.module';
import { SharedMaterialModule } from './main/shared/shared-material.module';

// Services
import { AuthenticationService } from './main/authentication/services/authentication.service';

// Components
import { AppComponent } from 'app/app.component';
import { LoginComponent } from './main/authentication/login/login.component';
import { ToolComponent } from './main/tool/tool.component';
import 'rxjs/add/operator/map';
import {SharedModule} from './main/shared/shared.module';
import {SharedEnums} from './main/shared/shared-enums';
import { AssetsMonitoringToolModule } from './main/assets-monitoring-tool/assets-monitoring.module';

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ToolComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([]),
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,

        // Shared materials
        SharedMaterialModule,
        SharedModule,

        AssetsMonitoringToolModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AuthenticationService,
        SharedEnums,
    ]
})
export class AppModule { }

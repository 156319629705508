import {Component} from '@angular/core';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';

@Component({
    selector: 'tool',
    templateUrl: './tool.component.html',
    styleUrls: ['./tool.component.scss']
})
export class ToolComponent {
    /**
     * Constructor
     *
     * @param _fuseSplashScreenService
     */
    constructor(
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        this._fuseSplashScreenService.hide();
    }
}
